<template>
  <v-select
    :items="filteredItems"
    :value="value"
    :label="label"
    :loading="isLoading"
    item-text="name"
    return-object
    :disabled="disabled"
    @change="emitInput"
    :rules="rules"
  ></v-select>
</template>

<script>
import { find } from "@/services/types-service";
import { mapGetters } from "vuex";
export default {
  //props: ["label", "selected", "disabled", "value"],
  props: {
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Array,
      default: () => [],
    },
    filterItemsBySlug: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      types: [],
    };
  },
  mounted() {
    this.list();
  },
  computed: {
    ...mapGetters("loading", ["isLoading"]),
    filteredItems() {
      if (this.filterItemsBySlug?.length) {
        return this.types.filter((type) => {
          return this.filterItemsBySlug.includes(type.slug);
        });
      }
      return this.types;
    }
  },
  methods: {
    async list() {
      try {
        let { data } = await find({ active: true });
        this.types = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    emitInput(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
