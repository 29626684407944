import api from "@/services/api-service";
import querystring from "querystring";

const url = "/types";

export const find = (query) => {
  return api().get(`${url}?${querystring.stringify(query)}`);
};

export const create = (obj) => {
  return api().post(url, obj);
};

export const update = (obj) => {
  return api().post(url, obj);
};

export const updateOrder = (types) => api().put(url + "/order", types);
